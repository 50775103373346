
import {defineComponent} from "vue";
import KTMenu from "@/layout/aside/Menu.vue";
import AppQuickAction from "@/layout/aside/tabs/QuickAction.vue";
import {
  minimizedAsideSecondary,
  asideSecondaryDisplay,
  minimizationEnabled,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KtAsideSecondary",
  components: {
    KTMenu,
    AppQuickAction,
  },
  setup() {
    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
    };
  },
});
