import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "aside-secondary d-flex flex-row-fluid"
}
const _hoisted_2 = {
  id: "kt_aside_wordspace",
  class: "aside-workspace my-5 p-5"
}
const _hoisted_3 = { class: "d-flex h-100 flex-column" }
const _hoisted_4 = {
  class: "flex-column-fluid hover-scroll-y",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-wrappers": "#kt_aside_wordspace",
  "data-kt-scroll-dependencies": "#kt_aside_secondary_footer",
  "data-kt-scroll-offset": "0px"
}
const _hoisted_5 = { class: "tab-content" }
const _hoisted_6 = {
  id: "kt_aside_nav_tab_menu",
  class: "tab-pane fade active show",
  role: "tabpanel"
}
const _hoisted_7 = {
  id: "kt_aside_nav_tab_add",
  class: "tab-pane fade",
  role: "tabpanel"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2 rotate-180" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_AppQuickAction = _resolveComponent("AppQuickAction")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.asideSecondaryDisplay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_KTMenu)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_AppQuickAction)
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.asideSecondaryDisplay && _ctx.minimizationEnabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass([{ active: _ctx.minimizedAsideSecondary }, "btn btn-sm btn-icon btn-white btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex"]),
          "data-kt-toggle": "true",
          "data-kt-toggle-state": "active",
          "data-kt-toggle-target": "body",
          "data-kt-toggle-name": "aside-minimize",
          style: {"margin-bottom":"1.35rem"}
        }, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr063.svg" })
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}