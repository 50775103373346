
import {defineComponent, computed, onMounted} from "vue";
import {StickyComponent} from "@/assets/ts/components";
import {headerWidthFluid} from "@/core/helpers/config";
import {headerFixed, headerFixedOnMobile} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: {
      type: String,
      default: ""
    },
    breadcrumbs: {
      type: Array,
      default() {
        []
      }
    },
  },
  setup() {
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    onMounted(() => {
      StickyComponent.reInitialization();
    });

    return {
      headerWidthFluid,
      isHeaderSticky,
    };
  },
});
