const DocMenuConfig = [
    {
        pages: [
            {
                heading: "dashboard",
                route: "/dashboard",
                svgIcon: "media/icons/duotune/art/art002.svg",
                fontIcon: "bi-app-indicator",
            },
        ],
    },
    {
        pages: [
            {
                sectionTitle: "employee",
                route: "/employee",
                svgIcon: "media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-person",
                sub: [
                    {
                        heading: "searchEmployee",
                        route: "/employee/detail/1",
                    },
                    {
                        heading: "employeeOverview",
                        route: "/employee/overview",
                    },
                    {
                        heading: "newEmployee",
                        route: "/employee/add/wizard/1",
                    },
                ],
            },
            {
                sectionTitle: "factory",
                route: "/factory",
                svgIcon: "media/icons/duotune/general/gen022.svg",
                fontIcon: "bi-archive",
                sub: [
                    {
                        heading: "searchFactory",
                        route: "/crafted/pages/profile/campaigns",
                    },
                    {
                        heading: "factoryOverview",
                        route: "/factory/overview",
                    },
                    {
                        heading: "newFactory",
                        route: "/crafted/pages/profile/projects",
                    },
                ],
            },
            {
                sectionTitle: "documents",
                route: "/account",
                svgIcon: "media/icons/duotune/communication/com006.svg",
                fontIcon: "bi-person",
                sub: [
                    {
                        heading: "searchDocument",
                        route: "/crafted/account/settings",
                    },
                    {
                        heading: "documentOverview",
                        route: "/crafted/account/overview",
                    },
                ],
            },
        ],
    },

];

export default DocMenuConfig;
