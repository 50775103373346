
import {defineComponent} from "vue";

export default defineComponent({
  name: "AppQuickAction",
  components: {},
  setup() {
    const items = [
      {
        icon: "media/icons/duotune/communication/com006.svg",
        color: "success",
        title: "Nový zaměstnanec",
        description: "Přidat nového zaměstnance",
        router: "addEmployeeStep1",
      },
      {
        icon: "media/icons/duotune/finance/fin006.svg",
        color: "primary",
        title: "Přehled středisek",
        description: "Zobrazení všech středisek",
        router: "factory",
      },
    ];

    return {
      items,
    };
  },
});
