
import {defineComponent} from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "KtAsidePrimary",
  components: {
    KTUserMenu,
  },
  methods: {
    showSideMenu() {
      let secondaryBtn: HTMLElement = document.querySelector("[data-kt-toggle-name='aside-minimize']") as HTMLElement;
      if (secondaryBtn && secondaryBtn.classList.contains('active')) {
        secondaryBtn.click()
      }


    }
  }
});
