
import { defineComponent } from "vue";
import { footerWidthFluid,appVersion } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
      appVersion
    };
  },

});
